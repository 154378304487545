@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

html, body, #root {
  height: 100%;
  background-color: #282c34;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #282c34;
}
::-webkit-scrollbar-corner {
  background: #282c34;
}

::-webkit-scrollbar-thumb {
  background: #505c73;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #55637e;
}

@keyframes glow-effect {
  0% {
    box-shadow: #fff 0 0 0 0;
  }
  50% {
    box-shadow: #fff 0 0 0 3px;
  }
  100% {
    box-shadow: #fff 0 0 0 0;
  }
}

@keyframes bank-action-arrow-effect {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}